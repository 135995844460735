/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
/**
 * The storage service
 */
@Injectable({
  providedIn: 'root'
})
export class StorageService {
  /**
   * Obtaining information from storage
   * @param {string} _name item name
   * @returns {} Object data
   */
  public get(_name: string): any {
    if (_name === 'token') return localStorage.getItem(_name);
    const storage = localStorage.getItem(_name) || '';
    return JSON.parse(atob(storage));
  }
  /**
   * Save information to storage
   * @param {string} _name item name
   * @param {any} _value Save to data
   * @returns { void }
   */
  public set(_name: string, _value: any): void {
    if (_name === 'token') return localStorage.setItem(_name, _value);
    const text = JSON.stringify(_value);
    localStorage.setItem(_name, btoa(text));
  }
  /**
   * Clean up the entire storage
   */
  public clear(): void {
    localStorage.clear();
  }
}
