import { Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';

export const routes: Routes = [
  {
    path: 'chat',
    loadComponent: () => import('./pages/main/main.component'),
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/chat/chat.module'),
        canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: 'error/:error',
    loadComponent: () => import('./pages/error/error.component')
  }
];
